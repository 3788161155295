import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiService } from 'src/app/core/service/api.service';
import { environment } from 'src/environments/environment';
import {
  ReviewedTeam,
  SearchTeam,
  TeamWithCurrency,
} from '../classes/SearchTeam';

@Injectable({
  providedIn: 'root',
})
export class AccreditationRequestDetailsService {
  refreshDetails = new BehaviorSubject<boolean>(false);
  requestDetails = new BehaviorSubject<any>(null);
  requestToken = new BehaviorSubject<string>(null);
  teamToken = new BehaviorSubject<string>(null);
  paymentToken = new BehaviorSubject<string>(null);
  manDays = new BehaviorSubject<any>(null);
  requestCurrency = new BehaviorSubject<any>(null);

  formSelectedTeam = new BehaviorSubject<SearchTeam[]>([]);
  formReviewedTeam = new BehaviorSubject<ReviewedTeam[]>([]);
  suggestRepresentativeTeam = new BehaviorSubject<ReviewedTeam[]>([]);
  formCommitteeData = new BehaviorSubject<any>(null);
  teamWithCurrency = new BehaviorSubject<TeamWithCurrency[]>([]);
  formRecommindation = new BehaviorSubject<any>(null);
  formCommitteeConvener = new BehaviorSubject<any>(null);

  addNewRequest = new BehaviorSubject<boolean>(false);
  renewRequest = new BehaviorSubject<boolean>(false);
  extendRequest = new BehaviorSubject<boolean>(false);
  editRequest = new BehaviorSubject<boolean>(false);
  requestType = new BehaviorSubject<any>(null);

  choosenCabToken = new BehaviorSubject<any>(null);

  constructor(private apiService: ApiService) {}

  getRequestDetails(token) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab/cab_request_details`, token)
      .pipe(catchError(this.handleError));
  }
  checkCabAccreditationSchema(model) {
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/cab/check_cab_accreditation_schema`,
        model
      )
      .pipe(catchError(this.handleError));
  }
  getRequestTimeline(filter) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab/cab_request_timeline`, filter)
      .pipe(catchError(this.handleError));
  }
  dynamicAction(model, attachments = null) {
    let data;
    if (attachments) {
      const formData = new FormData();
      if (attachments && attachments.length) {
        attachments.forEach((file) => {
          formData.append(
            model.multiAttached ? 'attached_file[]' : 'attached_file',
            file,
            file.name
          );
          formData.append(
            model.multiAttached ? 'attachments[]' : 'attachments',
            file,
            file.name
          );
        });
      }
      for (const prop in model) {
        if (model.hasOwnProperty(prop) && prop !== 'endPoint') {
          formData.append(prop, String(model[prop]));
        }
      }
      data = formData;
    } else {
      data = model;
    }
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab/` + model.endPoint, data)
      .pipe(catchError(this.handleError));
  }
  acceptRequest(model, enCertificateFile, arCertificateFile, scopesFile) {
    const formData = new FormData();
    if (enCertificateFile.length) {
      formData.append(
        'certificate_file',
        enCertificateFile[0],
        enCertificateFile[0].name
      );
    }
    if (arCertificateFile.length) {
      formData.append(
        'certificate_ar_file',
        arCertificateFile[0],
        arCertificateFile[0].name
      );
    }
    if (scopesFile.length) {
      formData.append('scopes_file', scopesFile[0], scopesFile[0].name);
    }
    for (const prop in model) {
      if (model.hasOwnProperty(prop) && prop !== 'endPoint') {
        if (prop === 'token_scopes') {
          formData.append(prop + '[]', model[prop]);
        } else {
          formData.append(prop, model[prop]);
        }
      }
    }
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab/` + model.endPoint, formData)
      .pipe(catchError(this.handleError));
  }
  getRequestScopes(model) {
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/cab/get_all_request_scopes`,
        model
      )
      .pipe(catchError(this.handleError));
  }
  searchManagers(endPoint, model) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab/` + endPoint, model)
      .pipe(catchError(this.handleError));
  }
  getDocumentDetails(filter) {
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/cab/get_request_documents_attachments`,
        filter
      )
      .pipe(catchError(this.handleError));
  }

  addRequestDocuments(data) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab/save_document_request`, data)
      .pipe(catchError(this.handleError));
  }
  getScopesDetails(filter) {
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/cab/get_request_details_scopes`,
        filter
      )
      .pipe(catchError(this.handleError));
  }
  getrequestDetailsTab(data) {
    // get_request_main_details
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/cab/get_request_main_details`,
        data
      )
      .pipe(catchError(this.handleError));
  }
  getFileManagerRecommendations(data) {
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/cab/get_request_file_manager_recommendation`,
        data
      )
      .pipe(catchError(this.handleError));
  }
  getPaymentDetailsTab(data) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab/cab_request_payments`, data)
      .pipe(catchError(this.handleError));
  }
  getRequestDetailsContract(data) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab/get_request_contract`, data)
      .pipe(catchError(this.handleError));
  }
  editContractAgreement(data) {
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/cab/edit_contract_agreement`,
        data
      )
      .pipe(catchError(this.handleError));
  }
  getContractTemplate() {
    return this.apiService
      .get(`${environment.apiUrl}/api/system/cab/get_contract_agreement`)
      .pipe(catchError(this.handleError));
  }
  // document review tab
  // getDocumentReview(data) {
  //   return this.apiService
  //   .post(`${environment.apiUrl}/api/system/cab/document_review_team`, data)
  //   .pipe(catchError(this.handleError));
  // }
  getsingleTeamView(data) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab/get_single_team_view`, data)
      .pipe(catchError(this.handleError));
  }
  getTeamVisitDates(data) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab/get_team_visit_dates`, data)
      .pipe(catchError(this.handleError));
  }
  // getConfidentialityAgreements(data) {
  //   return this.apiService
  //   .post(`${environment.apiUrl}/api/system/cab/document_review_team_confidentiality_agreement`, data)
  //   .pipe(catchError(this.handleError));
  // }
  getEvidences(data) {
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/cab/document_review_team_evidences`,
        data
      )
      .pipe(catchError(this.handleError));
  }
  getReports(data) {
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/cab/document_review_team_reports`,
        data
      )
      .pipe(catchError(this.handleError));
  }
  // forwardDetailsToDrTeam(model, attachments = null) {
  //   const formData = new FormData();
  //   if (attachments && attachments.length) {
  //     attachments.forEach(file => {
  //       formData.append('files_doc[]', file, file.name);
  //     });
  //   }

  //   // formData.append('data', JSON.stringify(model));
  //   for (const prop in model) {
  //     if (model.hasOwnProperty(prop) && prop !== 'endPoint') {
  //       if (prop === 'attachment_documents') {
  //         model.attachment_documents.forEach(doc => {
  //           formData.append('attachment_type[]', doc.attachment_type);
  //           doc.files.forEach((file, j) => {
  //             formData.append('attachment_documents[' + j + '][document_token]', file.document_token);
  //             file.document_viewers_tokens.forEach(memberToken => {
  //               formData.append('attachment_documents[' + j + '][document_viewers_tokens][]', memberToken);
  //             });
  //           });
  //         });
  //       } else {
  //         formData.append(prop, model[prop]);
  //       }
  //     }
  //   }
  //   return this.apiService
  //     .post(`${environment.apiUrl}/api/system/cab/` + model.endPoint, formData)
  //     .pipe(catchError(this.handleError));
  // }

  forwardDetailsToDrTeam(model, attachments = null) {
    // const formData = new FormData();
    // if (attachments && attachments.length) {
    //   attachments.forEach(file => {
    //     formData.append('files_doc[]', file, file.name);
    //   });
    // }
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab/` + model.endPoint, model)
      .pipe(catchError(this.handleError));
  }

  searchTeam(filter) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab/search_suggest_team`, filter)
      .pipe(catchError(this.handleError));
  }
  searchConvener(filter) {
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/search_users_convener_for_cab`,
        filter
      )
      .pipe(catchError(this.handleError));
  }
  searchCommittee(filter) {
    // search_create_committee
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/cab/search_technical_committee`,
        filter
      )
      .pipe(catchError(this.handleError));
  }
  listAllCommittees(filter) {
    let endPoint: any;
    switch (filter.endPoint) {
      case 'ed_create_appealing_committee':
        endPoint = 'list_all_appealing_committees';
        break;
      case 'ed_create_advisory_committee':
        endPoint = 'list_all_committees';
        break;
      case 'ed_create_technical_committee':
        endPoint = 'list_all_technical_committees';
        break;
      default:
        break;
    }
    // const endPoint = filter.endPoint === 'ed_create_appealing_committee' ? 'list_all_appealing_committees' : 'list_all_technical_committees';
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab/${endPoint}`, filter)
      .pipe(catchError(this.handleError));
  }

  handleError(error) {
    return throwError(error);
  }

  // common (document review tab, pre Assessment Visit) //---------

  // getTeamMembers(data, endPoint) {
  //   return this.apiService
  //     .post(`${environment.apiUrl}/api/system/cab/` + endPoint, data)
  //     .pipe(catchError(this.handleError));
  // }
  // getAsseesmentConfidentialityAgreements(data, endPoint) {
  //   return this.apiService
  //     .post(`${environment.apiUrl}/api/system/cab/` + endPoint, data)
  //     .pipe(catchError(this.handleError));
  // }
  getDiscussions(data, endPoint) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab/` + endPoint, data)
      .pipe(catchError(this.handleError));
  }
  sendDiscussionData(data, attachments) {
    const formData = new FormData();
    if (attachments && attachments.length) {
      attachments.forEach((file) => {
        formData.append('attachments[]', file, file.name);
      });
    }

    for (const prop in data) {
      if (data.hasOwnProperty(prop)) {
        let values = data[prop];
        if (prop === 'notes') {
          values = JSON.stringify(data[prop]);
        }
        formData.append(prop, values);
      }
    }

    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/cab/team_member_add_comment`,
        formData
      )
      .pipe(catchError(this.handleError));
  }
  generalGetData(data) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab/${data.endPoint}`, data)
      .pipe(catchError(this.handleError));
  }
  getCurrencyList(data) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab/get_currency_list`, data)
      .pipe(catchError(this.handleError));
  }
  changeRequestCurrency(data) {
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/cab/change_request_currency `,
        data
      )
      .pipe(catchError(this.handleError));
  }
  addPaymentOrders(data: any) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab/ad_add_payment_order`, data)
      .pipe(catchError(this.handleError));
  }
  updatePaymentOrder(data: any) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab/edit_payment_item`, data)
      .pipe(catchError(this.handleError));
  }
  // downloadAttachments(filePath) {
  //   return this.apiService.get(
  //     `${environment.apiUrl}/api/system/cab/download_attachments_request?att=${filePath}`
  //   );
  // }

  uploadReportsFileVisit(data) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab/report_files_visit`, data)
      .pipe(catchError(this.handleError));
  }
  closeReportTeam(data) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab/close_report_team`, data)
      .pipe(catchError(this.handleError));
  }
  replayReportTeam(data) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab/replay_from_cab`, data)
      .pipe(catchError(this.handleError));
  }

  getMyCabs() {
    return this.apiService
      .get(`${environment.apiUrl}/api/system/cab/get_my_cabs`)
      .pipe(catchError(this.handleError));
  }
  getInitialFindings(filter) {
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/cab/initial_findings_and_clearance_report`,
        filter
      )
      .pipe(catchError(this.handleError));
  }
  addEditFindings(data, attachments, editMode) {
    const formData = new FormData();
    if (attachments && attachments.length) {
      attachments.forEach((file) => {
        formData.append('attached_file[]', file, file.name);
      });
    }

    for (const prop in data) {
      if (data.hasOwnProperty(prop)) {
        let values = data[prop];
        // if (prop === 'notes') {
        //   values = JSON.stringify(data[prop]);
        // }
        formData.append(prop, values);
      }
    }
    if (editMode) {
      return this.apiService
        .post(
          `${environment.apiUrl}/api/system/cab/update_findings_and_clearance_report`,
          formData
        )
        .pipe(catchError(this.handleError));
    } else {
      return this.apiService
        .post(
          `${environment.apiUrl}/api/system/cab/add_findings_and_clearance_report`,
          formData
        )
        .pipe(catchError(this.handleError));
    }
  }
  changeStatus(data) {
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/cab/change_status_findings_and_clearance_report`,
        data
      )
      .pipe(catchError(this.handleError));
  }
  addCabConformityEvidences(data, attachments, correctiveAction) {
    const formData = new FormData();
    if (attachments && attachments.length) {
      attachments.forEach((file) => {
        formData.append('attached_file[]', file, file.name);
      });
    }

    for (const prop in data) {
      if (data.hasOwnProperty(prop)) {
        let values = data[prop];
        formData.append(prop, values);
      }
    }
    console.log(correctiveAction);

    if (correctiveAction) {
      return this.apiService
        .post(
          `${environment.apiUrl}/api/system/cab/cab_upload_corrective_action`,
          formData
        )
        .pipe(catchError(this.handleError));
    } else {
      return this.apiService
        .post(
          `${environment.apiUrl}/api/system/cab/cab_upload_non_conformity_evidences`,
          formData
        )
        .pipe(catchError(this.handleError));
    }
  }
  getInitialAssessmentFeedback(filter) {
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/cab/initial_assessment_feedback`,
        filter
      )
      .pipe(catchError(this.handleError));
  }
  getInitialFeedbackQuestions(filter) {
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/cab/getInitialFeedbackQuestions`,
        filter
      )
      .pipe(catchError(this.handleError));
  }
  addInitialFeedbackAnswers(filter) {
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/cab/addInitialFeedbackAnswers`,
        filter
      )
      .pipe(catchError(this.handleError));
  }
  getFeedbackMember(filter) {
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/cab/getFeedbackMember`,
        filter
      )
      .pipe(catchError(this.handleError));
  }

  deleteOrderAttatchment(filter) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab/deleteOrderAttatchment`, filter)
      .pipe(catchError(this.handleError));
  }

  paymentReportExportPdf(model) {
    return this.apiService
      .getDownload(
        `${environment.apiUrl}/api/system/cab/paymentReportExportPdf`,
        model
      )
      .pipe(catchError(this.handleError));
  }

  paymentContractExportPdf(model) {
    return this.apiService
      .getDownload(
        `${environment.apiUrl}/api/system/cab/paymentContractExportPdf`,
        model
      )
      .pipe(catchError(this.handleError));
  }

  /////////////////////////////////////
  /////////////////////////////////////
  //downloadFile//
  /////////////////////////////////////
  /////////////////////////////////////

  downloadFile(model) {
    return this.apiService
      .getDownload(
        `${environment.apiUrl}/api/system/cab/download_attachments_request`,
        model
      )
      .pipe(catchError(this.handleError));
  }

  downloadAlldocuments(model) {
    return this.apiService
      .getDownload(
        `${environment.apiUrl}/api/system/cab/download_cab_documents_zip`,
        model
      )
      .pipe(catchError(this.handleError));
  }
}
