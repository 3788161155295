<nav class="navbar head-nav" *ngIf="showNav">
  <div>
    <h2>CABs</h2>
  </div>
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a>Home</a>
    </li>
    <li class="breadcrumb-item">CABs</li>
    <li class="breadcrumb-item">All Cabs</li>
  </ol>
</nav>
<div class="page">
  <div class="page-filters">
    <div class="count-item">
      <span class="counter"> {{ pageCount }} </span>
      <span class="title">CABs</span>
    </div>
    <div class="sort-item">
      <label>Sorted By:</label>
      <mat-form-field appearance="outline" class="sort-filter">
        <mat-select (selectionChange)="sortRecords($event)" [(value)]="sortItemsSelected">
          <mat-option *ngFor="let sortItem of sortItems" [value]="sortItem.value">
            {{ sortItem.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="filters-container">
      <form (submit)="makeSearch()" class="search-input">
        <mat-form-field *ngIf="showSearch" appearance="legacy">
          <input (ngModelChange)="resetAfterClear()" [(ngModel)]="cabListFilterModel.search_key"
            [ngModelOptions]="{ standalone: true }" autocomplete="off" matInput />
          <mat-icon (click)="resetSearch(); showSearch = false" matSuffix>clear
          </mat-icon>
        </mat-form-field>
        <button (click)="makeSearch()" mat-icon-button>
          <mat-icon>search</mat-icon>
        </button>
      </form>
      <!-- <button
        (click)="showFilter = !showFilter"
        [ngClass]="{ active: showFilter }"
        class="btn dropdown-custom dropdown-toggle"
      >
        <img alt="" src="../../../../../assets/images/icons/filter-icon.svg"/>
        <span>Filter</span>
        <svg height="5.001" viewBox="0 0 8 5.001" width="8">
          <path
            d="M-3.562-9.316-.5-6.215l3.062-3.1.938.949-4,4.051-4-4.051Z"
            fill="#6a6a6a"
            id="menu-icon"
            opacity="0.8"
            transform="translate(4.5 9.316)"
          />
        </svg>
      </button> -->
      <div class="create-item">
        <button (click)="openCreateDialog()" *ngIf="false" class="btn customBtn mr-2" color="primary" mat-raised-button>
          <span> Add New CAB</span>
        </button>
        <button (click)="openDirectPublish()" *ngIf="showDirectPublish" class="btn customBtn" color="primary"
          mat-raised-button>
          <span> Direct Publish</span>
        </button>
      </div>
    </div>
    <div [hidden]="!showFilter" class="filter-section">
      <div class="row">
        <!-- <div class="col-xl-2 col-lg-4 mb-2">
          <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput (keyup)="makeFilter()"
            [(ngModel)]="cabListFilterModel.name"/>
            <button
              (click)="cabListFilterModel.name = undefined; makeFilter()"
              *ngIf="cabListFilterModel.name"
              aria-label="Clear"
              mat-button
              mat-icon-button
              matSuffix
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="col-xl-2 col-lg-4 mb-2">
          <mat-form-field>
            <mat-label>Status</mat-label>
            <mat-select
              (selectionChange)="makeFilter()"
              [(ngModel)]="cabListFilterModel.status"
            >
              <mat-option value="0"> Accredited</mat-option>
              <mat-option value="1"> Suspended </mat-option>
              <mat-option value="2"> Withdrawn </mat-option>
              <mat-option value="3"> Expired </mat-option>
            </mat-select>
            <button
              (click)="cabListFilterModel.status = undefined; makeFilter()"
              *ngIf="cabListFilterModel.status"
              aria-label="Clear"
              mat-button
              mat-icon-button
              matSuffix
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="col-xl-2 col-lg-4 mb-2">
          <mat-form-field>
            <mat-label>Country</mat-label>
            <mat-select
              (selectionChange)="makeFilter()"
              [(ngModel)]="cabListFilterModel.country"
            >
            <mat-option
              *ngFor="let item of countryCodes"
              [value]="item.country_token"
            >
              {{ item.countryName }}
            </mat-option>
            </mat-select>
            <button
              (click)="cabListFilterModel.country = undefined; makeFilter()"
              *ngIf="cabListFilterModel.country"
              aria-label="Clear"
              mat-button
              mat-icon-button
              matSuffix
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div> -->
        <!-- <div class="col-lg-4 mb-2">
          <div class="position-relative">
              <mat-form-field class="form-group" (click)="showScopesTree = true"
                  [class.loading-field]="citiesLoading">
                  <mat-label>Scopes</mat-label>
                  <mat-chip-list #chipCitiesList>
                      <mat-chip *ngFor="let node of selectedCities; index as i;" [hidden]="i >= 5"
                          (removed)="selectedCities.splice(i, 1)">
                          {{node.scope_name}}
                      </mat-chip>
                      <div *ngIf="selectedCities.length > 5" class="more-plus"
                          (click)="showScopesTree = true">+{{selectedCities.length - 5}}</div>
                      <input style="width: 1px;" autocomplete="off" matInput [formControl]="cities"
                          [matChipInputFor]="chipCitiesList">
                  </mat-chip-list>
                  <mat-icon matSuffix>{{showScopesTree ? 'expand_more' : 'expand_less'}}</mat-icon>
              </mat-form-field>
              <div class="multi-tree-dropdown" *ngIf="scopesTree.length" [hidden]="!showScopesTree">
                  <app-multi-tree-selection [TREE_DATA]='scopesTree' [isView]="false"
                      (SelectedItems)="updateSelectedCities($event);makeFilter();">
                  </app-multi-tree-selection>
              </div>
              <div id="popOverlay" *ngIf="showScopesTree"></div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
  <div *ngIf="dataLoading" class="dataLoading">
    <mat-spinner class="m-auto" diameter="50"></mat-spinner>
  </div>
  <ng-container *ngIf="!dataLoading">
    <div *ngIf="recordsData; else dataEmpty">
      <div *ngIf="recordsData.length; else dataEmpty" class="table-responsive">
        <table class="table table-primary">
          <thead>
            <tr>
              <th width="1%"></th>
              <th width="15%">CAB Name</th>
              <th width="1%"></th>
              <th width="17%">CAB Admin</th>
              <th width="20%">Email</th>
              <th width="1%"></th>
              <th width="17%">CAB Representative</th>
              <th width="20%">Created On</th>
              <th width="10%"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let record of recordsData">
              <td>
                <div class="img-cricle">
                  <div class="image m-auto">
                    <img (error)="record.cab_logo = ''" *ngIf="record.cab_logo" [src]="record.cab_logo" />
                    <div *ngIf="!record.cab_logo" [innerHTML]="record.cab_name_en | shortName"></div>
                  </div>
                </div>
              </td>
              <td>
                <div class="cab-en">
                  {{ record.cab_name_en }}
                </div>
                <div class="cab-ar">
                  {{ record.cab_name_ar }}
                </div>
              </td>
              <td>
                <div class="img-cricle">
                  <div class="image m-auto">
                    <img (error)="record.cab_admin_logo = ''" *ngIf="record.cab_admin_logo"
                      [src]="record.cab_admin_logo" />
                    <div *ngIf="!record.cab_admin_logo" [innerHTML]="record.cab_name | shortName"></div>
                  </div>
                </div>
              </td>
              <td>
                <div class="cab-en">
                  {{ record.cab_name }}
                </div>
              </td>
              <td>
                <span class="link"> {{ record.cab_email }}</span>
              </td>
              <td>
                <div class="img-cricle" *ngIf="record.representative_name || record.representative_logo
              ">
                  <div class="image m-auto">
                    <img (error)="record.representative_logo = ''" *ngIf="record.representative_logo"
                      [src]="record.representative_logo" />
                    <div *ngIf="!record.representative_logo" [innerHTML]="record.representative_name | shortName"></div>
                  </div>
                </div>
              </td>
              <td>
                <div class="cab-en">
                  {{ record.representative_name }}
                </div>
              </td>
              <td>
                <span> {{ record.cab_created_at | unixDate }}</span>
              </td>
              <td>
                <div class="btn-actions">
                  <a (click)="navigateToProfile(record.baisc_token)" mat-icon-button matTooltip="View">
                    <mat-icon>visibility</mat-icon>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="egac-paginator">
        <mat-paginator (page)="getAll($event)" [length]="pageCount" [pageIndex]="pageIndex"
          [pageSizeOptions]="[5, 10, 20, 40, 60, 80, 100]" [pageSize]="cabListFilterModel.per_page"></mat-paginator>
      </div>
    </div>
  </ng-container>
  <ng-template #dataEmpty>
    <div class="data-empty">
      <p>No Data Found</p>
    </div>
  </ng-template>
</div>