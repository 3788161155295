<div [ngClass]="{ active: docsEditable }" class="files-preview-container">
  <div class="row" [ngClass]="{ 'pdf-preview': preview }">
    <div *ngFor="let file of files; let i = index" [ngClass]="{ 'col-md-6': cols == 2 }" class="col-12">
      <div class="file-item" [ngClass]="{ 'new': file?.newFile == '1' }">
        <div class="file-details">
          <div class="file-icon">
            <img *ngIf="fileType(file.name) == 'doc'" src="./assets/images/file-icons/file-doc-icon.svg" />
            <img *ngIf="fileType(file.name) == 'jpg'" src="./assets/images/file-icons/file-jpg-icon.svg" />
            <img *ngIf="fileType(file.name) == 'pdf'" src="./assets/images/file-icons/file-pdf-icon.svg" />
            <img *ngIf="fileType(file.name) == 'png'" src="./assets/images/file-icons/file-png-icon.svg" />
            <img *ngIf="fileType(file.name) == 'xls'" src="./assets/images/file-icons/file-xls-icon.svg" />
            <img *ngIf="fileType(file.name) == 'zip'" src="./assets/images/file-icons/file-zip-icon.svg" />
            <img *ngIf="fileType(file.name) == 'pptx'" src="./assets/images/file-icons/file-ppt-icon.svg" />
            <!--  -->
            <img *ngIf="fileType(file.name) == 'gif'" src="./assets/images/file-icons/file-doc-icon.svg" />
            <img *ngIf="fileType(file.name) == 'tiff'" src="./assets/images/file-icons/file-doc-icon.svg" />
            <img *ngIf="fileType(file.name) == 'bmp'" src="./assets/images/file-icons/file-doc-icon.svg" />
          </div>
          <div>
            <label [title]="file?.name">{{ file?.name }}</label>
            <span *ngIf="file?.size">{{ formatBytes(file?.size) }}</span>
            <!-- <span>{{ file.size }}</span> -->
          </div>
        </div>
        <div class="file-btn">
          <!-- <a
            class="pl-2 pr-2 preview"
            target="_blank"
            *ngIf="preview && file?.type == 'pdf'"
            [href]="file?.path"
          >
            <mat-icon class="mat-18">visibility</mat-icon>
          </a> -->
          <a class="pl-2 pr-2 preview" mat-icon-button *ngIf="deleteFiles" (click)="deletedFile.emit(file)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20.001" viewBox="0 0 16 20.001">
              <path id="delete-icon"
                d="M442.031,477.64a1.857,1.857,0,0,1-1.844-1.786l-.532-11.339h-.337a.62.62,0,0,1-.615-.625h0V461.7a1.553,1.553,0,0,1,1.539-1.562h3.384v-.625a1.863,1.863,0,0,1,1.846-1.875h2.461a1.863,1.863,0,0,1,1.846,1.875v.625h3.385A1.551,1.551,0,0,1,454.7,461.7v2.188a.62.62,0,0,1-.615.625h-.336l-.532,11.339a1.857,1.857,0,0,1-1.844,1.786Zm-.614-1.845a.619.619,0,0,0,.614.6h9.344a.619.619,0,0,0,.614-.6l.53-11.28H440.888ZM439.935,461.7v1.563h13.537V461.7a.31.31,0,0,0-.307-.312H440.242a.31.31,0,0,0-.307.31Zm4.923-2.187v.625h3.691v-.625a.62.62,0,0,0-.615-.625h-2.461a.621.621,0,0,0-.614.624Zm4.307,15v-8.125a.615.615,0,1,1,1.231,0v8.125a.615.615,0,1,1-1.231,0Zm-3.077,0v-8.125a.615.615,0,1,1,1.231,0v8.125a.615.615,0,1,1-1.231,0Zm-3.077,0v-8.125a.615.615,0,1,1,1.231,0v8.125a.615.615,0,1,1-1.231,0Z"
                transform="translate(-438.703 -457.641)" fill="#d60000" />
            </svg>
          </a>
          <a class="download" (click)="onDocumentDownloader(file.download, file.name , file.name)"
            *ngIf="!downloadWithApi" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20.001" viewBox="0 0 20 20.001">
              <path id="download"
                d="M2.4,20A2.506,2.506,0,0,1,0,17.507V11.418a.3.3,0,0,1,.093-.2l3.07-5.917a.435.435,0,0,1,.418-.22H5.349a.49.49,0,0,1,0,.978H3.86L1.3,10.954h2.6A3.041,3.041,0,0,1,6.7,12.837a2.157,2.157,0,0,0,1.953,1.3h2.7a2.156,2.156,0,0,0,1.953-1.3,3.042,3.042,0,0,1,2.791-1.883h2.651l-2.163-4.89H14.651a.49.49,0,0,1,0-.978h2.233a.461.461,0,0,1,.442.245l2.6,5.892a.467.467,0,0,1,.069.2v6.088A2.506,2.506,0,0,1,17.6,20ZM9.674,11.32,6.512,7.971a.509.509,0,0,1,0-.587.451.451,0,0,1,.652-.1L9.535,9.78V.49a.466.466,0,1,1,.931,0V9.78l2.372-2.494a.447.447,0,0,1,.558,0,.506.506,0,0,1,.093.685L10.326,11.32l-.027.029a.428.428,0,0,1-.625-.029Z"
                fill="#e48d1a" />
            </svg>
          </a>
          <a class="download" (click)="download(file.tokenDownload, file.name , file.name)" *ngIf="downloadWithApi"
            target="_blank">
            <svg height="20.001" viewBox="0 0 20 20.001" width="20" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2.4,20A2.506,2.506,0,0,1,0,17.507V11.418a.3.3,0,0,1,.093-.2l3.07-5.917a.435.435,0,0,1,.418-.22H5.349a.49.49,0,0,1,0,.978H3.86L1.3,10.954h2.6A3.041,3.041,0,0,1,6.7,12.837a2.157,2.157,0,0,0,1.953,1.3h2.7a2.156,2.156,0,0,0,1.953-1.3,3.042,3.042,0,0,1,2.791-1.883h2.651l-2.163-4.89H14.651a.49.49,0,0,1,0-.978h2.233a.461.461,0,0,1,.442.245l2.6,5.892a.467.467,0,0,1,.069.2v6.088A2.506,2.506,0,0,1,17.6,20ZM9.674,11.32,6.512,7.971a.509.509,0,0,1,0-.587.451.451,0,0,1,.652-.1L9.535,9.78V.49a.466.466,0,1,1,.931,0V9.78l2.372-2.494a.447.447,0,0,1,.558,0,.506.506,0,0,1,.093.685L10.326,11.32l-.027.029a.428.428,0,0,1-.625-.029Z"
                fill="#e48d1a" id="download" />
            </svg>
          </a>

        </div>
      </div>
    </div>
  </div>
</div>
