import { AuthService } from '../../../features/auth/services/auth.service';
import { UserManagementService } from '../../../features/user-management/service/user-management.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountryISO, PhoneNumberFormat, SearchCountryField, } from 'ngx-intl-tel-input';
import { ToastrService } from 'ngx-toastr';
import { whiteSpaceValidator } from 'src/app/shared/Validators/no-white-space';
import { UserTypeTokens } from '../../classes/userTypeTokens';

@Component({
  selector: 'app-egac-form',
  templateUrl: './create-form.component.html',
})
export class CreateFormComponent implements OnInit {
  loading = false;
  selectedUserType: any = {};
  egacCreateForm: FormGroup;
  countryCodes: any[] = [];
  countryCode: any;
  countries: [] = [];
  cities: [] = [];
  Nationalities: [] = [];
  userType;

  emailLoader = false;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  userTypeTokens: UserTypeTokens = new UserTypeTokens();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateFormComponent>,
    private userManagementService: UserManagementService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.egacCreateFormInit();
    this.onGetCountryCode();
    console.log(this.data);
  }

  egacCreateFormInit() {
    this.egacCreateForm = this.formBuilder.group({
      firstName: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z ]+$'),
          whiteSpaceValidator,
        ],
        ,
      ],
      fatherName: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z ]+$'),
          whiteSpaceValidator,
        ],
        ,
      ],
      grandfatherName: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z ]+$'),
          whiteSpaceValidator,
        ],
      ],
      familyName: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z ]+$'),
          whiteSpaceValidator,
        ],
        ,
      ],
      position: [''],
      nationality: ['', Validators.required],
      phone: [null, [Validators.required, Validators.minLength(10)]],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '[a-zA-Z0-9._-]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}'
          ),
        ],

      ],
      cabNameEnglish: [
        '',
        [Validators.pattern('^[0-9a-zA-Z_\\\/().-]+$'), whiteSpaceValidator],
      ],
      cabNameArabic: [
        '',
        [Validators.pattern('^(?=.*[\u0600-\u06FF])[\u0600-\u06FF0-9 _\\\/().-]+$')],
      ],
    });
  }

  hasError(controlName: string, errorName: string) {
    return this.egacCreateForm.controls[controlName].hasError(errorName);
  }

  onEgacCreateSubmit() {
    // this.onFilterByCountryCode();
    if (this.egacCreateForm.valid) {
      this.loading = true;
      const modal = {
        first_name: this.egacCreateForm.controls.firstName.value,
        father_name: this.egacCreateForm.controls.fatherName.value,
        grandfather_name: this.egacCreateForm.controls.grandfatherName.value,
        family_name: this.egacCreateForm.controls.familyName.value,
        position: this.egacCreateForm.controls.position.value,
        nationality_token: this.egacCreateForm.controls.nationality.value,
        phone: this.egacCreateForm.controls.phone.value.nationalNumber,
        email: this.egacCreateForm.controls.email.value,
        phone_code_token: this.egacCreateForm.controls.phone.value.countryCode,
        cab_name_en: this.egacCreateForm.controls.cabNameEnglish.value,
        cab_name_ar: this.egacCreateForm.controls.cabNameArabic.value,
      };
      if (this.data.userType == this.userTypeTokens.egacStaff) {
        this.onCreateEgacStaff(modal);
      } else if (this.data.userType == this.userTypeTokens.cabUser) {
        this.onCreateCabsUser(modal);
      } else if (this.data.userType == this.userTypeTokens.trainee) {
        this.onCreateTainees(modal);
      } else if (this.data.userType == this.userTypeTokens.externalStaff) {
        this.onCreateExternal(modal);
      } else if (this.data.userType == this.userTypeTokens.cabRepresentative) {
        this.onCreateCabRepresentative(modal);
      }
    }
  }

  onCreateEgacStaff(modal) {
    this.userManagementService.addEgacUser(modal).subscribe(
      (res) => {
        this.loading = false;
        this.dialogRef.close(true);
        this.showSuccess(res.message);
      },
      (err) => {
        this.loading = false;
        err.message.forEach((el) => {
          this.showError(el.message);
        });
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < err.message.length; i++) {
          if (err.message[i].field == 'email') {
            this.egacCreateForm.controls.email.setErrors({
              serverError: `${err.message[i].message}`,
            });
            this.egacCreateForm.updateValueAndValidity();
          }

          if (err.message[i].field == 'first_name') {
            this.egacCreateForm.controls.firstName.setErrors({
              serverError: `${err.message[i].message}`,
            });
          }
        }
      }
    );
  }

  onCreateCabsUser(modal) {
    this.userManagementService.addCabsUser(modal).subscribe(
      (res) => {
        this.loading = false;
        this.dialogRef.close(true);
        this.showSuccess(res.message);
      },
      (err) => {
        this.loading = false;
        err.message.forEach((el) => {
          this.showError(el.message);
        });
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < err.message.length; i++) {
          if (err.message[i].field == 'email') {
            this.egacCreateForm.controls.email.setErrors({
              serverError: `${err.message[i].message}`,
            });
            this.egacCreateForm.updateValueAndValidity();
          }

          if (err.message[i].field == 'first_name') {
            this.egacCreateForm.controls.firstName.setErrors({
              serverError: `${err.message[i].message}`,
            });
          }
        }
      }
    );
  }

  onCreateTainees(modal) {
    this.userManagementService.addTrainee(modal).subscribe(
      (res) => {
        this.loading = false;
        this.dialogRef.close(true);
        this.showSuccess(res.message);
      },
      (err) => {
        this.loading = false;
        err.message.forEach((el) => {
          this.showError(el.message);
        });
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < err.message.length; i++) {
          if (err.message[i].field == 'email') {
            this.egacCreateForm.controls.email.setErrors({
              serverError: `${err.message[i].message}`,
            });
            this.egacCreateForm.updateValueAndValidity();
          }

          if (err.message[i].field == 'first_name') {
            this.egacCreateForm.controls.firstName.setErrors({
              serverError: `${err.message[i].message}`,
            });
          }
        }
      }
    );
  }

  onCreateExternal(modal) {
    this.userManagementService.addExternalUser(modal).subscribe(
      (res) => {
        this.loading = false;
        this.dialogRef.close(true);
        this.showSuccess(res.message);
      },
      (err) => {
        this.loading = false;
        err.message.forEach((el) => {
          this.showError(el.message);
        });
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < err.message.length; i++) {
          if (err.message[i].field == 'email') {
            this.egacCreateForm.controls.email.setErrors({
              serverError: `${err.message[i].message}`,
            });
            this.egacCreateForm.updateValueAndValidity();
          }

          if (err.message[i].field == 'first_name') {
            this.egacCreateForm.controls.firstName.setErrors({
              serverError: `${err.message[i].message}`,
            });
          }
        }
      }
    );
  }

  onCreateCabRepresentative(modal) {
    this.userManagementService.addCAB_Representative(modal).subscribe(
      (res) => {
        this.loading = false;
        this.dialogRef.close(true);
        this.showSuccess(res.message);
      },
      (err) => {
        this.loading = false;
        err.message.forEach((el) => {
          this.showError(el.message);
        });
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < err.message.length; i++) {
          if (err.message[i].field == 'email') {
            this.egacCreateForm.controls.email.setErrors({
              serverError: `${err.message[i].message}`,
            });
            this.egacCreateForm.updateValueAndValidity();
          }

          if (err.message[i].field == 'first_name') {
            this.egacCreateForm.controls.firstName.setErrors({
              serverError: `${err.message[i].message}`,
            });
          }
        }
      }
    );
  }


  onGetCountryCode() {
    this.authService.getCountryCode().subscribe((res) => {
      this.countryCodes = res.data;
    });
  }

  onFilterByCountryCode() {
    const countryCodeValue =
      this.egacCreateForm.controls.phone.value.countryCode;
    this.countryCode = this.countryCodes.filter((item) => {
      return item.country_iso_code == countryCodeValue;
    });
  }

  showError(msg) {
    this.toastr.error(msg);
  }

  showSuccess(msg) {
    this.toastr.success(msg);
  }
}
