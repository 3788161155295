// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // dev
  // apiUrl: 'https://egacapidev.mosandah.com.sa',
  // wsUrl: 'wss://egacapidev.mosandah.com.sa:443',

  // stage
  // apiUrl: 'https://egacstapi.expertapps.com.sa',
  // wsUrl: 'wss://egacstapi.expertapps.com.sa:443',

  // test
  // apiUrl: 'https://egacapi.expertapps.com.sa',
  // wsUrl: 'wss://egacapi.expertapps.com.sa:443',

  // stage2
  // apiUrl: 'https://egacstageapi.expertapps.com.sa',
  // wsUrl: 'wss://egacstageapi.expertapps.com.sa:443',

  // production
  // apiUrl: 'https://systemapi.egac.gov.eg',
  // wsUrl: 'wss://systemapi.egac.gov.eg:443',

  // CR Environment Variables
  apiUrl: 'https://egaccrapi.mosandah.com.sa',
  wsUrl: 'wss://egaccrapi.mosandah.com.sa:443',

  apiUrlTest: 'http://laravel.aioqr.online',
  googleApiKey: '6LcnZZ8kAAAAAK6JD5581UcNtKT8GwbBS_ZtXUW6',
  googleSiteKey: '6LcnZZ8kAAAAAK6JD5581UcNtKT8GwbBS_ZtXUW6',
};

// export const environment = {
//   production: true,
//   apiUrl: 'https://egacstapi.expertapps.com.sa',
//   apiUrlTest: 'http://laravel.aioqr.online',
//   googleApiKey: '6Lfpg9UbAAAAAPUD-yVlkRcIMWcHH7aOrSVLbSRk',
//   googleSiteKey: '6Lfpg9UbAAAAAPUD-yVlkRcIMWcHH7aOrSVLbSRk',
//   wsUrl: 'wss://egacstapi.expertapps.com.sa:443',
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
